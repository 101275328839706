import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Container,
  Icon,
  Menu,
  Responsive,
  Segment,
  Visibility
} from 'semantic-ui-react'

import HomepageHeading from '../components/HomepageHeading'
import { contactEmail } from "../lib/Data";
import { getWidth } from "../lib/Utils";

class DesktopContainer extends Component {
  state = {}

  hideFixedMenu = () => this.setState({ fixed: false })
  showFixedMenu = () => this.setState({ fixed: true })

  render() {
    const { children } = this.props
    const { fixed } = this.state

    return (
      <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth}>
        <Visibility
          once={false}
          onBottomPassed={this.showFixedMenu}
          onBottomPassedReverse={this.hideFixedMenu}
        >
          <Segment
            className='app-header'
            inverted
            textAlign='center'
            style={{ minHeight: 350, padding: '1em 0em' }}
            vertical
          >
            <Menu
              className='menu-noborder'
              fixed={fixed ? 'top' : null}
              inverted={!fixed}
              pointing={!fixed}
              secondary={!fixed}
              size='large'
            >
              <Container>
                {/* <Menu.Item as='a' href='/'>Accueil</Menu.Item> */}
                {/* <Menu.Item>
                <Image size='small' src='/images/lineops.png' />
                </Menu.Item> */}
                <Menu.Item as='a' href='/#recruit'>Rejoingnez-nous</Menu.Item>
                <Menu.Item as='a' rel="noopener noreferrer" target='_blank' href='https://time.lineops.com'>Saisie des Temps</Menu.Item>
                <Menu.Item as='a' href='/news'>News</Menu.Item>
                <Menu.Item position='right'>
                  <Button as='a' target='_blank' rel="noopener noreferrer" href={`mailto:${contactEmail}`} inverted={!fixed} primary={fixed} style={{ marginLeft: '0.5em' }} >
                  <Icon name='mail' />
                    {contactEmail}
                  </Button>
                  <Button as='a' rel="noopener noreferrer" href='https://github.com/lineops' target='_blank' inverted={!fixed} primary={fixed} style={{ marginLeft: '0.5em' }}>
                  <Icon name='github' />
                    GitHub
                  </Button>
                </Menu.Item>
              </Container>
            </Menu>
            <HomepageHeading />
          </Segment>
        </Visibility>

        {children}
      </Responsive>
    )
  }
}
  
  DesktopContainer.propTypes = {
    children: PropTypes.node,
  }


  export default DesktopContainer;