import React, { Component } from 'react'
import PropTypes from 'prop-types';
import '../styles/Home.css';
import {
  Grid,
  Header,
  Segment,
  Item
} from 'semantic-ui-react'
import MobileContainer from '../containers/mobileContainer'
import DesktopContainer from '../containers/desktopContainer'
import HomepageFooter from './HomepageFooter'
import config from '../config'
import moment from 'moment';

const API_URL = config.apiUrl

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

function timestampToDate(stamp) {
  var dateString = moment.unix(stamp).format("DD/MM/YYYY");
  return dateString
}


class News extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      isLoading: true
    };
  }
  
  componentDidMount() {
      document.title = `Flux d'actualités - Devops & Security - LINEOPS`;
      this.setState({ isLoading: true }, () => {
        fetch(API_URL + '/news')
        .then(response => response.json())
        .then(data => this.setState({ data: data.data, isLoading: false }));
      });
  }


  render() {
    return (
      <ResponsiveContainer>
        <Header as='h1' style={{ fontSize: '1.66em', paddingTop: '2em' }} textAlign='center'>Société spécialisée Devops, Sécurité et Cloud.</Header>
        
        <Segment style={{ padding: '0em' }} vertical id='news' loading={this.state.isLoading}>
          <Header as='h3' style={{ fontSize: '1.66em', paddingTop: '2em' }} textAlign='center'>
          DERNIERS ARTICLES
          </Header>
          <Grid celled='internally' columns='equal' stackable>
            <Grid.Row >

              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>

              </Grid.Column>

              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }} width="12">
                <Item.Group divided>
                {this.state.data.map((article) => 
                    <Item key={article.id} as='a' rel="noopener noreferrer" target='_blank' href={article.link}>

                      <Item.Content>
                        <Item.Header as='a' >{article.title}</Item.Header>
                        <Item.Meta>
                          <span className='cinema'>{article.summary}</span>
                        </Item.Meta>
                        <Item.Extra>{timestampToDate(1596205683)}</Item.Extra>
                      </Item.Content>
                      
                    </Item>
                )}
                </Item.Group>


              </Grid.Column>

              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>

              </Grid.Column>

            </Grid.Row>
          </Grid>
        </Segment>
        { HomepageFooter }

      </ResponsiveContainer>
    )
  }
}

export default News;
