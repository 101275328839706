import React, { Component } from 'react'
import PropTypes from 'prop-types';
import '../styles/Home.css';
import {
  Grid,
  Header,
  Segment,
  Image,
  Icon,
  Statistic,
  Item,
  Label,
} from 'semantic-ui-react'
import MobileContainer from '../containers/mobileContainer'
import DesktopContainer from '../containers/desktopContainer'
import HomepageFooter from './HomepageFooter'
import { contactEmail } from '../lib/Data';

const jobs = [
  {
    title: 'Ingénieur(e) Systèmes Linux (H/F)',
    img: '/images/tux.webp',
    location: 'Paris, France - Poste permanent',
    techs: 'CentOS/Redhat, Debian, Ansible',
    salary: '50-65k€/An'
  },
  {
    title: 'Developpeur(euse) Python (H/F)',
    img: '/images/python.svg',
    location: 'Paris, France - Poste permanent',
    techs: 'django, flask, mongodb, elasticsearch',
    salary: '50-65k€/An'
  },
  {
    title: 'Ingénieur(e) Devops (H/F)',
    img: '/images/devops.webp',
    location: 'Paris, France - Poste permanent',
    techs: 'Jenkins, Docker, Ansible, Terraform',
    salary: '55-65k€/An'
  },
  {
    title: 'Ingénieur(e) sécurité (H/F)',
    img: '/images/security.webp',
    location: 'Lyon, France - Poste permanent',
    techs: 'iptables, selinux, splunk, snort, nessus',
    salary: '55-65k€/An'
  },
  {
    title: 'Developpeur(euse) JAVA (H/F)',
    img: '/images/java.webp',
    location: 'Paris, France - Poste permanent',
    techs: 'Spring',
    salary: '55-65k€/An'
  },
  {
    title: 'Developpeur(euse) ReactJs (H/F)',
    img: '/images/react.svg',
    location: 'Paris, France - Poste permanent',
    techs: 'ReactJs',
    salary: '60-65k€/An'
  }
]

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      job: {
        title: ""
      }
    };
  }

  close = () => this.setState({ open: false })

  render() {
    return (
      <ResponsiveContainer>
        <Header as='h1' style={{ fontSize: '1.66em', paddingTop: '2em' }} textAlign='center'>Société spécialisée Devops, Sécurité et Cloud. Nos experts sont à votre écoute.</Header>
        <Segment style={{ padding: '0em' }} vertical>
          <Header as='h2' style={{ fontSize: '1.66em', paddingTop: '2em' }} textAlign='center'>
            NOTRE EXPERTISE<Icon name='angle double down'/>
          </Header>
          <Grid celled='internally' columns='equal' stackable>
            <Grid.Row textAlign='center'>

              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }} className='column-hover'>
                <Image src='/images/devops.webp' alt='devops' size='tiny' centered/>
                <Header as='h2' style={{ fontSize: '2em' }} textAlign='center'>
                  Devops
                </Header>
                <p style={{ fontSize: '1.33em' }}>
                  CI / CD<br></br>
                  Automatisation<br></br>
                  Tests de Performances<br></br>
                  Configuration centralisée<br></br>
                  Code Quality
                </p>
              </Grid.Column>

              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }} className='column-hover'>
                <Image src='/images/cloud.webp' alt='cloud' size='tiny' centered/>
                <Header as='h2' style={{ fontSize: '2em' }}>
                  Cloud & Infrastructure
                </Header>
                <p style={{ fontSize: '1.33em' }}>
                  Infrastructure as Code<br></br>
                  Migration dans le cloud<br></br>
                  AWS, GCP et Azure<br></br>
                  Monitoring 24/7<br></br>
                  Systèmes<br></br>
                  Réseaux
                </p>
              </Grid.Column>

              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }} className='column-hover'>
                <Image src='/images/security.webp' alt='security' size='tiny' centered/>
                <Header as='h2' style={{ fontSize: '2em' }} textAlign='center'>
                  Sécurité
                </Header>
                <p style={{ fontSize: '1.33em' }}>
                  Audit<br></br>
                  Secops<br></br>
                  Securité Continue<br></br>
                  Patch management<br></br>
                  Mise en conformité<br></br>
                  Pentest
                </p>
              </Grid.Column>

            </Grid.Row>
          </Grid>
        </Segment>

        <Segment style={{ padding: '0em' }} vertical>
          <Header as='h3' style={{ fontSize: '1.33em', paddingTop: '2em' }} textAlign='center'>
            QUELQUES CHIFFRES
            <Icon name='angle double down'/>
          </Header>
          <Grid celled='internally' columns='equal' stackable>

            <Grid.Row textAlign='center'>

              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                <Header as='h3' style={{ fontSize: '2em' }} textAlign='center'>
                  <Statistic color='blue'>
                    <Statistic.Value>19</Statistic.Value>
                    <Statistic.Label>Projets en cours</Statistic.Label>
                  </Statistic>
                </Header>
                <p style={{ fontSize: '1.33em' }}>
                  Nous nous efforçons de travailler sur des projets qui nous passionnent
                </p>
              </Grid.Column>
              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                <Header as='h3' style={{ fontSize: '2em' }} textAlign='center'>
                  <Statistic color='green'>
                    <Statistic.Value>100%</Statistic.Value>
                    <Statistic.Label>Clients satisfaits</Statistic.Label>
                  </Statistic>
                </Header>
                <p style={{ fontSize: '1.33em' }}>
                  Pour nous, la qualité des livrables et le respect des délais est une priorité.
                </p>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row textAlign='center'>
              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
                <Header as='h3' style={{ fontSize: '2em' }} textAlign='center'>
                  <Statistic color='orange'>
                    <Statistic.Value><Icon loading name='time' /></Statistic.Value>
                    <Statistic.Label>Gain de temps</Statistic.Label>
                  </Statistic>
                </Header>
                <p style={{ fontSize: '1.33em' }}>
                  Consultants, rendez-vous sur <a rel="noopener noreferrer" target='_blank' href='https://time.lineops.com'>Time</a> pour gagner du temps lors de la saisie de vos comptes rendus d'activité.
                </p>
              </Grid.Column>

            </Grid.Row>

          </Grid>
        </Segment>
        
        <Segment style={{ padding: '0em' }} vertical id='recruit'>
          <Header as='h3' style={{ fontSize: '1.66em', paddingTop: '2em' }} textAlign='center'>
          REJOIGNEZ-NOUS !
          </Header>
          <Grid celled='internally' columns='equal' stackable>
            <Grid.Row >

              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>

              </Grid.Column>

              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }} width={8}>
                <Item.Group divided>
                {jobs.map((job) => 
                    <Item key={job.title} as='a' rel="noopener noreferrer" target='_blank' href={`mailto:${contactEmail}?subject=Voici mon CV - ${job.title}`}>
                      {/* <Item.Image size='mini' src={job.img} alt={job.title} /> */}

                      <Item.Content>
                        <Item.Header >{job.title}</Item.Header>
                        <Item.Meta>
                          <span className='cinema'>{job.techs}</span>
                        </Item.Meta>
                          <Item.Description>Rémunération: <font color='#00b427'>{job.salary}</font></Item.Description>
                        <Item.Extra>
                          <Label icon='globe' content={job.location} />
                        </Item.Extra>
                      </Item.Content>
                    </Item>
                )}
                </Item.Group>


              </Grid.Column>

              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>

              </Grid.Column>

            </Grid.Row>
          </Grid>
        </Segment>
        { HomepageFooter }

      </ResponsiveContainer>
    )
  }
}

export default Home;
