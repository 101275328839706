const dev = {
    apiUrl: 'http://localhost:8000'
};

const prod = {
    apiUrl: 'https://api.lineops.com'
};

const config = process.env.REACT_APP_STAGE === 'prod' ? prod : dev;

export default {
  ...config
};