import React from 'react';
import {
  Container,
  Grid,
  Header,
  List,
  Segment,
  Icon
} from 'semantic-ui-react'

const HomepageFooter =  (
  <Segment inverted className='app-footer' vertical style={{ padding: '7em 0em' }}>
  <Container>
    <Grid divided inverted stackable>
      <Grid.Row>

        <Grid.Column width={3}>
          <Header inverted as='h4' content='Nous rejoindre' />
          <List link inverted>
            <p><List.Item as='a' style={{color: '#ffffff'}} href='/recruitments'> <Icon name='smile' /> Recrutement</List.Item></p>
          </List>
        </Grid.Column>

        <Grid.Column width={3}>
          <Header inverted as='h4' content='Consultants' />
          <List link inverted>
            <p>
              <a target='_blank' rel="noopener noreferrer" href='https://time.lineops.com' style={{color: '#ffffff'}}> <Icon name='time' />Feuilles de temps </a><br></br>
            </p>
          </List>
        </Grid.Column>

        <Grid.Column width={7}>
          <Header as='h4' inverted>
            Communauté
          </Header>
          <p>
            <a target='_blank' rel="noopener noreferrer" href='https://github.com/lineops' style={{color: '#ffffff'}}> <Icon name='github' /> Github </a><br></br>
          </p>
          <p>
            <a target='_blank' rel="noopener noreferrer" href='https://lineops.com/news' style={{color: '#ffffff'}}> <Icon name='newspaper' /> Actualités </a><br></br>
          </p>
        </Grid.Column>
        
        <Grid.Column width={3}>
          <Header inverted as='h4' content='LINEOPS' />
          <List link inverted>
            <p>
              <a target='_blank' rel="noopener noreferrer" href='mailto:contact@lineops.com' style={{color: '#ffffff'}}> <Icon name='mail' /> Contact </a><br></br>
            </p>
            <p>
              <a href='/legal' style={{color: '#ffffff'}}> <Icon name='legal' /> Mentions légales </a><br></br>
            </p>
          </List>
        </Grid.Column>

      </Grid.Row>
    </Grid>
  </Container>
</Segment>
  )

  export default HomepageFooter