import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'semantic-ui-css/semantic.min.css'
import { BrowserRouter } from 'react-router-dom'
import ReactGA from 'react-ga';

ReactGA.initialize('UA-37087623-5');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render((
    <BrowserRouter>
      <App />
    </BrowserRouter>
  ), document.getElementById('root'));
  
serviceWorker.unregister();
