import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Header
} from 'semantic-ui-react'

const HomepageHeading = ({ mobile }) => (
    <Container text>
      <Header
        as='h1'
        inverted
        style={{
          fontSize: mobile ? '2em' : '4em',
          fontWeight: 'normal',
          marginBottom: 0,
          marginTop: mobile ? '0.66em' : '0.66em',
          backgroundImage: "url('back.jpg') !important",
          backgroundSize: "cover !important",
        }}>
      <a className="logo-clickable" href="/"><font color='#00b427'>LINE</font><span className='neon'>OPS</span></a>
      </Header>
      <Header
        as='h2'
        content='Devops, Security and Cloud Infrastructure Experts'
        inverted
        style={{
          fontSize: mobile ? '1.5em' : '1.7em',
          fontWeight: 'normal',
          marginTop: mobile ? '0.5em' : '1.5em',
        }}
      />
      
    </Container>
    
  )
  
  HomepageHeading.propTypes = {
    mobile: PropTypes.bool,
  }

  export default HomepageHeading