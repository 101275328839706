import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Container,
  Icon,
  Menu,
  Responsive,
  Segment,
  Sidebar

} from 'semantic-ui-react'
import HomepageHeading from '../components/HomepageHeading'
import { contactEmail } from "../lib/Data.js";
import { getWidth } from "../lib/Utils";

class MobileContainer extends Component {
    state = {}
  
    handleSidebarHide = () => this.setState({ sidebarOpened: false })
  
    handleToggle = () => this.setState({ sidebarOpened: true })
  
    render() {
      const { children } = this.props
      const { sidebarOpened } = this.state
  
      return (
        <Responsive
          as={Sidebar.Pushable}
          getWidth={getWidth}
          maxWidth={Responsive.onlyMobile.maxWidth}
        >
          <Sidebar
            className='app-header'
            as={Menu}
            animation='push'
            inverted
            onHide={this.handleSidebarHide}
            vertical
            visible={sidebarOpened}
          >
            {/* <Menu.Item as='a' active>
              Accueil
            </Menu.Item> */}
            <Menu.Item as='a'>Rejoignez-nous</Menu.Item>
            <Menu.Item as='a' rel="noopener noreferrer" target='_blank' href='https://time.lineops.com'>Saisie des Temps</Menu.Item>
            <Menu.Item as='a' href='/news'>News</Menu.Item>
          </Sidebar>
  
          <Sidebar.Pusher dimmed={sidebarOpened}>
            <Segment
              className='app-header'
              inverted
              textAlign='center'
              style={{ minHeight: 250, padding: '1em 0em' }}
              vertical
            >
              <Container>
                <Menu inverted pointing secondary size='large' className='menu-noborder'>
                  <Menu.Item onClick={this.handleToggle}>
                    <Icon name='sidebar' />
                  </Menu.Item>
                  <Menu.Item position='right'>
                    <Button as='a' target='_blank' rel="noopener noreferrer" href={`mailto:${contactEmail}`} inverted>
                      Contact
                    </Button>
                    <Button 
                      as='a' 
                      href='https://github.com/lineops'
                      target='_blank'
                      rel="noopener noreferrer" 
                      inverted style={{ marginLeft: '0.5em' }}>
                      GitHub
                    </Button>
                  </Menu.Item>
                </Menu>
              </Container>
              <HomepageHeading mobile />
            </Segment>
  
            {children}
          </Sidebar.Pusher>
        </Responsive>
      )
    }
  }
  
  MobileContainer.propTypes = {
    children: PropTypes.node,
  }


  export default MobileContainer;