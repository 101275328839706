import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom'
import Home from './components/Home'
import News from './components/News'
import Jobs from './components/Jobs'
import LegalComponent from './components/Legal'

const NoMatchPage = () => {
  return <h3>404 - Not found</h3>;
};

class App extends Component {
    render() {

      return (
        <Switch>
          <Route exact path='/' component={Home}/>
          <Route exact path='/news' component={News}/>
          <Route exact path='/news/*' component={News}/>
          <Route exact path='/jobs' component={Jobs}/>
          <Route path='/legal' component={LegalComponent}/>
          <Redirect from='/recruitments' to='/jobs' />
          <Redirect from='/recrutement' to='/jobs' />
          <Route component={NoMatchPage} status={404} />
        </Switch>
      );
    }
}


export default App;