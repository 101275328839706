import React, { Component } from 'react'
import PropTypes from 'prop-types';
import '../styles/Home.css';
import {
  Grid,
  Header,
  Segment
} from 'semantic-ui-react'
import MobileContainer from '../containers/mobileContainer'
import DesktopContainer from '../containers/desktopContainer'
import HomepageFooter from './HomepageFooter'

const ResponsiveContainer = ({ children }) => (
  <div>
    <DesktopContainer>{children}</DesktopContainer>
    <MobileContainer>{children}</MobileContainer>
  </div>
)

ResponsiveContainer.propTypes = {
  children: PropTypes.node,
}

class LegalComponent extends Component {

  render() {
    return (
      <ResponsiveContainer>
        <Header as='h1' style={{ fontSize: '1.66em', paddingTop: '2em' }} textAlign='center'>Société spécialisée Devops, Sécurité et Cloud.</Header>
        
        <Segment style={{ padding: '0em' }} vertical id='legal'>
          <Header as='h3' style={{ fontSize: '1.66em', paddingTop: '2em' }} textAlign='center'>
          MENTIONS LÉGALES
          </Header>
          <Grid celled='internally' columns='equal' stackable>
            <Grid.Row >
              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
              </Grid.Column>
              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '2em' }} width="8">

              <h3>Éditeur</h3>
              LINEOPS
              <br></br>
              Raison sociale : SASU
              <br></br>
              Numéro d’inscription au registre du commerce et des sociétés (RCS) : Paris 497 933 408
              <br></br>
              Adresse : LINEOPS, 5 RUE DES SUISSES 75014 PARIS
              <br></br>
              <br></br>
              <h3>Hébergement</h3>
              <br></br>
              Les sites <b>lineops.com</b>, <b>time.lineops.com</b> et <b>hire.lineops.com</b> sont hébergés par la société Amazon Web Services:
              <br></br>
              Amazon Web Services LLC
              <br></br>
              P.O. Box 81226
              <br></br>
              Seattle, WA 98108-1226
              <br></br>
              <a href="http://aws.amazon.com">http://aws.amazon.com</a>


              </Grid.Column>

              <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>

              </Grid.Column>

            </Grid.Row>
          </Grid>
        </Segment>
        { HomepageFooter }

      </ResponsiveContainer>
    )
  }
}

export default LegalComponent;
